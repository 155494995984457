import {useLocation, useNavigate, useParams} from "react-router-dom";
import Surveys from "../surveys/Surveys";
import "../subOrganizations/SubOrganizations.css"
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Member from "../members/Member";
import jwtDecode from "jwt-decode";
import {getDataFromLocalStorage, isUnauthorized} from "../../helpers/utils";
import SubOrganizations from "./SubOrganizations";
import {useState, useEffect, useRef} from "react";
import {getOrgById, resetGetOrgByIdDataReducer} from "../../reduxStore/orgSlices/orgSlices";
import {resetGetUserByIdReducer} from "../../reduxStore/usersSlices/userSlice";
import {useDispatch, useSelector} from "react-redux";
import Skeleton from "react-loading-skeleton";
import CustomReport from "../customReport/CustomReport";
import Attempts from "../attempts/Attempts";
import Strategy from "../strategy/Strategy";
import {Roles} from "../../helpers/accessControl";

const SubOrganizationDetails = ({orgId = null}) => {

    const token = jwtDecode(getDataFromLocalStorage("token"))
    const orgPreferenceColor = localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : "#fc6620"
    const isOrgLevelSys = token.orgLevel === "SYSTEM"
    const isRoleOrgAdmin = token.roles.includes(Roles.ORG_ADMIN)
    const isRoleManager = token.roles.includes(Roles.MANAGER)

    const navigate = useNavigate();
    const {subOrgId} = useParams();
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const [orgName, setOrgName] = useState(null)
    const [isCreateSubOrg, setIsCreateSubOrg] = useState(pathname.startsWith("/subOrganizations"))
    const org = useSelector(state => state.org)
    const [activeTab, setActiveTab] = useState("Members")


    useEffect(() => {
        if (subOrgId !== undefined) {
            dispatch(getOrgById(subOrgId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setOrgName(res.payload?.data?.name)
                    // dispatch(getUserById(res.payload.data.ownerUserId)).then(res => {
                    //     if (isUnauthorized(res)) {
                    //         navigate("/")
                    //     }
                    // })
                }
            })
        }


        return () => {
            dispatch(resetGetOrgByIdDataReducer())
            dispatch(resetGetUserByIdReducer())
        }
    }, [])

    const handleOnClick=(selectedTab)=>{
        setActiveTab(selectedTab)
    }

    return (
        <>
            <div className={orgId === null ? "row main_content box_design1" : ""}>
                {
                    orgId === null &&

                    <>
                        <div className="sub-organization-details mt-2 mb-3">

                            <div
                                style={{color: orgPreferenceColor}}
                                className={"sub-org-data mediumFontSize "} onClick={() => {
                                navigate(-1)
                            }}>
                                <span className={"sub-org-back-btn "} style={{background: orgPreferenceColor}}>
                                    <i className="fa fa-solid fa-arrow-left"/>&nbsp;&nbsp;
                                </span>


                                {org.getOrgByIdDataReducer.loading ?

                                    <>
                                        <Skeleton width={120} height={20}/>
                                    </>

                                    :
                                    <>
                                        {orgName === null || orgName === undefined ? "" : orgName}
                                    </>

                                }

                            </div>

                        </div>

                    </>

                }
                <div className={"tabs-container px-0"}>
                    <Tabs className={"tabs-outer"} >
                        <TabList>
                            <Tab onClick={()=>{handleOnClick("Members")}} style={activeTab === "Members" ? {backgroundColor: orgPreferenceColor}:{} }>Members</Tab>
                            <Tab onClick={()=>{handleOnClick("Reflections")}}  style={activeTab === "Reflections" ? {backgroundColor: orgPreferenceColor} : {}}>Reflections</Tab>
                            <Tab onClick={()=>{handleOnClick("Attempts")}}  style={activeTab === "Attempts" ? {backgroundColor: orgPreferenceColor} : {}}>Attempts</Tab>
                            <Tab onClick={()=>{handleOnClick("Reports")}} style={activeTab === "Reports" ? {backgroundColor: orgPreferenceColor} : {}}>Reports</Tab>
                            {
                                (isRoleOrgAdmin || (isRoleManager && isOrgLevelSys)) &&
                                <Tab onClick={()=>{handleOnClick("Strategy")}}  style={activeTab === "Strategy" ? {backgroundColor: orgPreferenceColor} : {}}>Strategy</Tab>
                            }


                            {
                                token?.orgLevel !== "CUSTOMER" && !isCreateSubOrg &&
                                <Tab onClick={()=>{handleOnClick("Sub-Organizations")}} style={activeTab === "Sub-Organizations" ? {backgroundColor:orgPreferenceColor} : {}}>Sub-Organizations</Tab>
                            }
                        </TabList>


                        <TabPanel>
                            <div style={{borderTop: `2px solid ${orgPreferenceColor}`}}>
                                <Member isSubOrg={true} subOrgId={orgId === null ? subOrgId : orgId}
                                        isChildSubOrg={true}/>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div style={{borderTop: `2px solid ${orgPreferenceColor}`}}>
                                <Surveys isSubOrg={true} subOrgId={orgId === null ? subOrgId : orgId}/>
                            </div>

                        </TabPanel>

                        <TabPanel>
                            <div style={{borderTop: `2px solid ${orgPreferenceColor}`}}>
                                <Attempts isSubOrg={true} subOrgId={orgId === null ? subOrgId : orgId}/>
                            </div>

                        </TabPanel>

                        <TabPanel>
                            <div style={{borderTop: `2px solid ${orgPreferenceColor}`}}>
                                <CustomReport isSubOrg={true} subOrgId={orgId === null ? subOrgId : orgId}/>
                            </div>

                        </TabPanel>
                        {
                            (isRoleOrgAdmin || (isRoleManager && isOrgLevelSys)) &&
                            <TabPanel>
                                <div style={{borderTop: `2px solid ${orgPreferenceColor}`}}>
                                    <Strategy isSubOrg={true} subOrgId={orgId === null ? subOrgId : orgId}/>
                                </div>
                            </TabPanel>
                        }
                        {
                            token?.orgLevel !== "CUSTOMER" && !isCreateSubOrg&&
                            <TabPanel>
                                <div style={{borderTop: `2px solid ${orgPreferenceColor}`}}>
                                    <SubOrganizations isSubOrg={true} subOrgId={orgId === null ? subOrgId : orgId}/>
                                </div>
                            </TabPanel>
                        }






                    </Tabs>
                </div>
            </div>


        </>
    )
}
export default SubOrganizationDetails;
