import CryptoJS from 'crypto-js';
import {toast} from "react-toastify";
import {
    EmailRequired,
    FirstNameRequired,
    FirstNameTooLong,
    InvalidEmail,
    InvalidPhoneNumber, IsRequired,
    LastNameTooLong, Marks, MarksNumbers, MaxValueSmallerThenMinValue,
    OneQuestionAtLeastRequired, OptionsRequired, OptionValueRequired, OptionWeightSame, OrgLevelRequired,
    OrgNameRequired,
    PhoneNumberRequired, ProvideQuestionFor, ProvideQuestionForSegment, ProvideStrategyDescription, PulsePlans,
    QuestionType, ReflectionLevelRequired,
    ReflectionNameRequired, SameMaxValueAndMinValue, SameQuestionsOption, SelectAtLeastOne, SelectRange,
    SurveyMustContainRequiredQuestion, UpdateTeamErrorSelectAtLeastOne
} from "./constants";
import Skeleton from "react-loading-skeleton";
import {format} from "date-fns";
import React from "react";
import jwtDecode from "jwt-decode";
import {
    ActionEnum,
    AttemptSurveyOperation, AuthorityLevel, CustomReportCriteria,
    Inputs,
    Operators, OperatorsReport, ReflectionStatus,
    ReportFiled,
    roleHasAccess,
    Roles, RolesData,
    UserFields
} from "./accessControl";
import {FaInfoCircle} from "react-icons/fa";
import {Tooltip as ReactTooltip} from "react-tooltip";
import Select from "react-select";
import {Bar} from "react-chartjs-2";
import {FiTrash2} from "react-icons/fi";

// Encrypting Data
export const encryptData = (value) => {
    return CryptoJS.AES.encrypt(JSON.stringify(value), process.env.REACT_APP_SECRET_KEY).toString()
}

export function formatDate(inputDate) {
    if (inputDate === null || inputDate === undefined) {
        return ""
    }
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(inputDate.split('T')[0]);
    // const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const month = months[date.getMonth()]
    const day = ("0" + date.getDate()).slice(-2);
    const year = date.getFullYear().toString();
    return `${month}-${day}-${year}`;
}

export const getComputeData = (key, data) => {

    const dataList = [0, 0, 0, 0, 0, 0]
    const monthData = getCurrentAndPreviousMonths()

    data.map(dd => {
        if (dd.surveyName === key) {
            const monthName = getMonthName(dd.createdAt);

            const checkMonth = getMonthNameData(monthName)
            // if(checkMonth === "January"){
            //     dataList[2] =dd.avgCount || 0;
            // }
            // if(checkMonth === "February"){
            //     dataList[3] =dd.avgCount || 0
            //
            // }
            //
            // if(checkMonth === "March"){
            //     dataList[4] =dd.avgCount || 0
            //
            // }
            //
            // if(checkMonth === "April"){
            //     dataList[5] =dd.avgCount || 0
            //
            // }
            //
            // if(checkMonth === "May"){
            //     dataList[6] =dd.avgCount || 0
            //
            // }

            // if(checkMonth === "June"){
            //     dataList[0] =dd.avgCount || 0
            //
            // }

            if (checkMonth === monthData[0]) {
                dataList[0] = dd.avgCount

            }

            if (checkMonth === monthData[1]) {
                dataList[1] = dd.avgCoun

            }

            if (checkMonth === monthData[2]) {
                dataList[2] = dd.avgCount

            }

            if (checkMonth === monthData[3]) {
                dataList[3] = dd.avgCount

            }

            if (checkMonth === monthData[4]) {
                dataList[4] = dd.avgCount
            }

            if (checkMonth === monthData[5]) {
                dataList[5] = dd.avgCount || 0

            }

        }

    })

    return dataList

}

export function getColor(key, state, setState) {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    let shouldAppend = state?.filter(c => c?.name === key).length === 0;
    shouldAppend && state.push({name: key, color: randomColor})
    shouldAppend && setState(state);
    return randomColor;
}

export function getColorTester() {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

    return randomColor;
}

export const getDynamicColor = (surveyNames) => {
    const colorMap = {};
    const colors = [];
    surveyNames.forEach((name, index) => {
        const uniqueName = `${name}_${Date.now()}_${index}`; // Append timestamp and index
        colorMap[uniqueName] = colors.length; // Assign an index to each unique name
        const color = getRandomColor(); // Function to get a dynamic color
        colors.push(color);
    });

    return colors
}


const getMonthNameData = (month) => {
    switch (month) {
        case 0:
            return 'January';
        case 1:
            return 'February';
        case 2:
            return 'March';
        case 3:
            return 'April';
        case 4:
            return 'May';
        case 5:
            return 'June';
        case 6:
            return 'July';
        case 7:
            return 'August';
        case 8:
            return 'September';
        case 9:
            return 'October';
        case 10:
            return 'November';
        case 11:
            return 'December';
        default:
            return 'Unknown';
    }
};

const getMonthName = (dateString) => {
    const date = new Date(dateString);
    return date.getMonth();
};

export const formatDateToString = (originalDate) => {
    const month = (originalDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = originalDate.getUTCDate().toString().padStart(2, '0');
    const year = originalDate.getUTCFullYear();

    return `${month}-${day}-${year}`;
}
export const convertIntoDate = (originalDateToString) => {
    const parts = originalDateToString?.split('-');
    return new Date(parts?.[0], parts?.[1] - 1, parts?.[2]);
}
export const setDateFormat = (originalDate) => {
    const month = (originalDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = originalDate.getUTCDate().toString().padStart(2, '0');
    const year = originalDate.getUTCFullYear();
    return `${year}-${month}-${day}`;
}
export const convertInToString = (originalDate) => {
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month index
    const day = String(originalDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

export const moveArrayElements = (arr, sourceIndex, destinationIndex) => {
    console.log("arr====>old", arr)
    const newArray = [...arr];
    const [movedItem] = newArray.splice(sourceIndex, 1);
    newArray.splice(destinationIndex, 0, movedItem);
    console.log("arr====>new", newArray)
    return newArray;
};


export const removeItemFromArrayAtIndex = (arr, index) => {
    const newArray = arr?.filter((_, i) => i !== index);
    return newArray;
};

export const addItemAtIndex = (arr, index, value) => {
    const newArray = [...arr];
    newArray.splice(index, 0, value);
    return newArray;
};

export const filterCustomReport = (data) => {
    console.log("data ==> ", data)
    if (data !== "" || data !== undefined) {
        console.log("==> ", ReportFiled?.filter(cur => cur.value !== data))
        return ReportFiled?.filter(cur => cur.value !== data)
    }
    return ReportFiled

}


// Decrypting Data
export const decryptData = (value) => {

    return CryptoJS.AES.decrypt(value, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
}

export const EmptyBarChart = () => {
    // Define empty dataset
    const emptyData = {
        labels: [],
        datasets: [{
            label: 'Empty Dataset',
            backgroundColor: 'rgba(255, 99, 132, 0.2)', // Example color
            borderColor: 'rgba(255, 99, 132, 1)', // Example color
            borderWidth: 1,
            data: [],
        }],
    };

    // Chart options (customize as needed)
    const chartOptions = {
        maintainAspectRatio: false, // Adjust as needed
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: false,
                },
            }],
        },
        plugins: {
            responsive: true,
            legend: {
                display: false,
            },

        },
    };

    return (
        <div className="div-chart d-block">
            <h6>Reflection Summary Graph</h6>
            <div style={{height: "370px"}}>
                <Bar height={350} data={emptyData} options={chartOptions}/>
            </div>
        </div>
    );
};

export function validateReportObject(reportObject) {
    if (reportObject?.reportName === "" || reportObject?.reportName === null) {
        return {errorMessage: `Report Name can not be null`};
    }

    for (let reportCriteriaIndex = 0; reportCriteriaIndex < reportObject?.reportOperation.length; reportCriteriaIndex++) {
        const operation = reportObject?.reportOperation[reportCriteriaIndex];
        if (operation.selectOperand === "" || operation.selectOperand === null) {
            return `Report operand cannot be null or empty for operation ${reportCriteriaIndex + 1}`;
        }
        for (let criteriaIndex = 0; criteriaIndex < operation.reportCriteria.length; criteriaIndex++) {
            const dataChild = operation.reportCriteria[criteriaIndex];
            if (!dataChild.reportOperand) {
                return {errorMessage: ` Report filter  field cannot be null  of ${criteriaIndex + 1} `};
            }
            if (dataChild.reportOperand === 'firstName' || dataChild.reportOperand === 'lastName' || dataChild.reportOperand === 'name') {
                if (dataChild.reportCondition === '') {
                    return {errorMessage: `Condition type cannot be null ${criteriaIndex + 1}`};
                }
                if (dataChild?.operandValue?.length === 0 || dataChild?.operandValue === undefined) {
                    return {errorMessage: `Operand value cannot be null or empty ${criteriaIndex + 1}`};
                }
            }
            if (dataChild.reportOperand === 'roles' || dataChild.reportOperand === "status" || dataChild.reportOperand === "RefSelection" || dataChild.reportOperand === "UserSelection") {
                if (dataChild?.operandValue?.length === 0 || dataChild?.operandValue === undefined) {
                    return {errorMessage: `Operand value cannot be null or empty ${criteriaIndex + 1}`};
                }
            }
        }
    }

    if (reportObject?.reportType === "" || reportObject?.reportType === null) {
        return {errorMessage: `Report type can not be null`};
    }

    if (reportObject?.reportType === "LINE_CHART" || reportObject?.reportType === "BAR_CHART" || reportObject?.reportType === "BUBBLE_CHART") {

        if (reportObject?.reportVariables?.labelX === "") {
            return {errorMessage: `Report label X type can not be null`};
        }

        if (reportObject?.reportVariables?.titleX === "") {
            return {errorMessage: `Report title X type can not be null`};
        }

        if (reportObject?.reportVariables?.labelY === "") {
            return {errorMessage: `Report label Y type can not be null`};
        }

        if (reportObject?.reportVariables?.titleY === "") {
            return {errorMessage: `Report title Y type can not be null`};
        }
    }


}

export function formatDateToYYYYMMDD() {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth(); // Get the current month
    if (month === 0) { // If the current month is January
        // Adjust to December of the previous year
        month = 12;
    }
    month = (month).toString().padStart(2, '0'); // Pad with leading zero
    const day = date.getDate().toString().padStart(2, '0'); // Pad with leading zero
    return `${year}-${month}-${day}`;
}

export function generateDateFor(daysAgo) {
    if (daysAgo === null || daysAgo === undefined) {
        return new Date()
    }
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - daysAgo);
    return currentDate
}

export function isNullOrEmpty(value) {
    return value === null || value === undefined || value?.trim() === '';
}

export function isArrayEmpty(value) {
    return value.length === 0;
}

export function base64ConvertIntoBlob(base64Image) {
    const byteCharacters = atob(base64Image);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'image/png'})

    return blob
}

export function getFirstFiveElements(values) {
    if (values !== undefined && values?.length > 0) {
        return values.length > 4
            ? values.slice(0, 4).join(", ") + " ..."
            : values.join(", ");
    }

    return []
}

export const isUnauthorized = (res) => {
    if (res.meta.requestStatus === "rejected" && (res.payload.errorCode?.value === 401 || res.payload.data?.errorCode?.value === 401)) {
        localStorage.clear();
        return true
    }
    return false
}

export const handleApiResponse = (res, onSuccess, onReject) => {
    if (res.meta.requestStatus === "fulfilled" && onSuccess !== null && onSuccess !== undefined) {
        onSuccess();
    }
    if (res.meta.requestStatus === "rejected" && onReject !== null && onReject !== undefined) {
        onReject();
    }
}


// Getting Axios Configuration
export const getAxiosConfig = () => {
    return {
        headers: {
            'Authorization': `Bearer ${getDataFromLocalStorage("token")}`
        }
    }
}

export const getOrgLevel = (reference) => {
    let orgLevel = null
    if (reference === null) {
        orgLevel = "CUSTOMER"
    }
    if (reference === "CREATE_SUB_ORG") {
        orgLevel = "SUB_ORGANIZATION"
    }
    if (reference === "CREATE_ORG") {
        orgLevel = ""
    }
    return orgLevel
}

// Getting stripe Configuration
export const getStipeConfig = () => {
    return {
        headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`
        }
    }
}
// Get Decrypted Data From Local Storage
export const getDataFromLocalStorage = (key) => {
    return JSON.parse(decryptData(localStorage.getItem(key)))
}


export const emailRegExp = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/

export const validateSignUpFormData = (formData, cityList = []) => {
    let message;
    // Organization Data Validation
    if (isNullOrEmpty(formData.org.name)) {
        message = OrgNameRequired;
    }
    if (isNullOrEmpty(formData.org.orgLevel)) {
        message = OrgLevelRequired;
    }
        // TODO removed address validation
        // else if (isNullOrEmpty(formData.org.address)) {
        //     message = AddressRequired;
        // } else if (isNullOrEmpty(formData.org.country)) {
        //     message = CountryRequired;
        // } else if (isNullOrEmpty(formData.org.state)) {
        //     message = StateRequired;
        // } else if (cityList.length !== 0 && isNullOrEmpty(formData.org.city)) {
        //     message = CityRequired;
        // } else if (isNullOrEmpty(formData.org.postalCode)) {
        //     message = PostalCodeRequired;
        // } else if (formData.org.postalCode.length > 10 || formData.org.postalCode.length < 5) {
        //     message = InvalidPostalCode;
        // }
    // Contact Information Data Validation
    else if (isNullOrEmpty(formData.user.firstName)) {
        message = FirstNameRequired;
    } else if (isNullOrEmpty(formData.user.email)) {
        message = EmailRequired;
    } else if (formData.user.firstName.length > 25) {
        message = FirstNameTooLong;
    } else if (!isNullOrEmpty(formData.user.lastName) && formData.user.lastName.length > 25) {
        message = LastNameTooLong;
    } else if (!emailRegExp.test(formData.user.email)) {
        message = InvalidEmail;
    } else if (isNullOrEmpty(formData.user.contactNumber)) {
        message = PhoneNumberRequired;
    } else if (formData.user.contactNumber.length < 11 || formData.user.contactNumber.length > 15) {
        message = InvalidPhoneNumber;
    }

    if (message) {
        showToast(message, "error")
        return false;
    }
    return true;

}

export const groupQuestionsBySegmentIndex = (reflectionSegments, questions) => {
    const groupedQuestions = [];
    reflectionSegments?.forEach((segment, segmentIndex) => {
        groupedQuestions.push({
            index: segmentIndex,
            segmentName: segment?.segmentName,
            isShow: false,
            segmentDescription: segment?.segmentDescription,
            questionsList: questions.filter(cur => cur.segmentIndex === segmentIndex)
        });
    });
    return groupedQuestions;
};

export const convertToStartEndDateTime = (givenDate, convertToStartDateTime = true) => {
    const dateObject = new Date(givenDate);

    if (convertToStartDateTime) {
        dateObject.setHours(0, 0, 0, 0);
    } else {
        dateObject.setHours(23, 59, 59, 999);
    }

    return dateObject.toISOString();

}

export const formatMessage = (message = null, values = []) => {
    if (isNullOrEmpty(message)) {
        return ""
    }
    let replacedMessage = message;
    for (let i = 0; i < values.length; i++) {
        replacedMessage = replacedMessage.replace('{' + i + '}', values[i]);
    }
    return replacedMessage;
}

export const getOrdinalSuffix = (number) => {
    switch (number) {
        case 1: {
            return "1st"
        }
        case 2: {
            return "2nd"
        }
        case 3: {
            return "3rd"
        }
        default: {
            return number + "th"
        }
    }
}


export const validateSurveySubmit = (survey, segmentType, questionsList, reflectionSegments) => {

    if (isNullOrEmpty(survey.name)) {
        return ReflectionNameRequired;
    } else if (questionsList.length === 0) {
        return OneQuestionAtLeastRequired;
    } else {
        if (segmentType === "LINEAR") {
            for (let i = 0; i < questionsList.length; i++) {
                const question = questionsList[i];
                if (isNullOrEmpty(question.questionText)) {
                    return formatMessage(ProvideQuestionFor, [i + 1]);
                }
            }


        } else {
            for (let index = 0; index < reflectionSegments.length; index++) {
                // if(reflectionSegments[index].segmentName === null || reflectionSegments[index].segmentName === ''){
                //     message = "Segment can not be null of segment  no "+`${index + 1}!`;
                // }
                // if(reflectionSegments[index].questionsList.length === 0){
                //     message = "Question's can not empty segment no "+`${index + 1}!`;
                // }
                if (reflectionSegments[index].questionsList.length === 0) {
                    return `Please provide at least one question in segment no ${index + 1} `
                }
                for (let i = 0; i < reflectionSegments[index].questionsList.length; i++) {
                    const question = reflectionSegments[index].questionsList[i];
                    if (isNullOrEmpty(question.questionText)) {
                        return formatMessage(ProvideQuestionForSegment, [i + 1, index + 1]);
                    }
                }
            }
        }
    }

}

export const validateOptionsForOptionTypeQuestions = (question, i) => {
    if (question?.options?.length === 0 || question?.options?.some(c => isNullOrEmpty(c?.optionText))) {
        return formatMessage(OptionsRequired, [getOrdinalSuffix(i + 1)])
    }
    if (question?.options?.some(c => isNullOrEmpty(c?.optionValue))) {
        return formatMessage(OptionValueRequired, [getOrdinalSuffix(i + 1)])
    }

    const optionTexts = new Set();
    for (const option of question.options) {
        if (optionTexts.has(option.optionText)) {
            return formatMessage(SameQuestionsOption, [getOrdinalSuffix(i + 1)]);
        }
        optionTexts.add(option.optionText);
    }

    const optionValues = question?.options.map(option => option.optionValue);
    const uniqueOptionValues = new Set(optionValues);
    if (optionValues.length !== uniqueOptionValues.size) {
        return formatMessage(OptionWeightSame, [getOrdinalSuffix(i + 1)])
    }
}

export const validateRangeForNumberTypeQuestions = (question, i) => {
    if (question?.number?.min === undefined || question?.number?.max === undefined || question?.number?.min === null || question?.number?.max === null) {
        return formatMessage(SelectRange, [getOrdinalSuffix(i + 1)])
    }
    if ((parseFloat(question.number.max) < parseFloat(question.number.min))) {
        return formatMessage(MaxValueSmallerThenMinValue, [getOrdinalSuffix(i + 1)])
    }
    if (parseFloat(question.number.max) === parseFloat(question.number.min)) {
        return formatMessage(SameMaxValueAndMinValue, [getOrdinalSuffix(i + 1)])
    }
}


export const validatePublishSurvey = (survey, segmentType, questionsList, reflectionSegments, checkReflectionPlanLevel) => {

    let message = validateSurveySubmit(survey, segmentType, questionsList, reflectionSegments)
    if (message) {
        return message;
    }
    if (segmentType === "TABULAR") {
        for (let refIndex = 0; refIndex < reflectionSegments.length; refIndex++) {
            const ref = reflectionSegments[refIndex];
            for (let index = 0; index < ref.questionsList.length; index++) {
                const question = ref.questionsList[index];

                if (question.type === QuestionType.OPTION) {
                    message = validateOptionsForOptionTypeQuestions(question, index)
                    if (message) return message + " in section " + (refIndex + 1)
                }
                if (question.type === QuestionType.NUMBER) {
                    message = validateRangeForNumberTypeQuestions(question, index)
                    if (message) return message + " in section " + (refIndex + 1)
                }
            }
            if (checkReflectionPlanLevel && (roleHasAccess([Roles.ORG_ADMIN])) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM" && (survey.planLevel === undefined || survey.planLevel === "" || survey.planLevel === null)) {
                return ReflectionLevelRequired;
            } else if (!checkIsRequired(ref.questionsList)) {
                return SurveyMustContainRequiredQuestion;
            }
        }
    } else {

        for (let i = 0; i < questionsList.length; i++) {
            const question = questionsList[i];
            if (question.type === QuestionType.OPTION) {
                message = validateOptionsForOptionTypeQuestions(question, i)
                if (message) return message
            }
            if (question.type === QuestionType.NUMBER) {
                message = validateRangeForNumberTypeQuestions(question, i)
                if (message) return message
            }

        }
        if (checkReflectionPlanLevel && (roleHasAccess([Roles.ORG_ADMIN])) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM" && (survey.planLevel === undefined || survey.planLevel === "" || survey.planLevel === null)) {
            return ReflectionLevelRequired;
        }
        if (!checkIsRequired(questionsList)) {
            return SurveyMustContainRequiredQuestion;
        }
    }
    return message;

}
const isOptionTextDuplicate = (questionsList) => {
    let optionTypeQuestions = questionsList.filter(question => question.type === QuestionType.OPTION)

    for (let index = 0; index < optionTypeQuestions.length; index++) {
        let OptionA = optionTypeQuestions[index].options[0].optionText.trim();
        let OptionB = optionTypeQuestions[index].options[1].optionText.trim();
        let OptionC = optionTypeQuestions[index].options[2].optionText.trim();
        if (OptionA === OptionB || OptionA === OptionC || OptionB === OptionC) {
            return [true, index + 1]
        }
    }
    return [false, undefined - 1]


}

const checkIsRequired = (questionList) => {
    for (let i = 0; i < questionList.length; i++) {
        if (questionList[i].isRequired === true) {
            return true;
        }
    }
    return false;
};
const isOptionValueDuplicate = (questionsList) => {
    for (let i = 0; i < questionsList.length; i++) {
        const question = questionsList[i];
        const optionValues = question.options.map((option) => {
            return option.optionValue
        });
        const uniqueOptionValues = new Set(optionValues);

        if (optionValues.length !== uniqueOptionValues.size) {
            return i;
        }
    }
    return -1
};

const isOptionValueNull = (questionsList) => {
    for (let i = 0; i < questionsList.length; i++) {
        const question = questionsList[i];
        console.log("question===>", question)
        for (let j = 0; j < question.options.length; j++) {
            const cur = question.options[j];
            console.log("question cur option===>", cur)
            if (cur.optionValue === null || cur.optionValue === "" || cur.optionValue === "null" || cur.optionValue === undefined) {
                return [-1, i + 1];
            }
        }
    }
    return [1, 0];
};
export const compareAndSaveAttemptedQuestionData = (attempted, questions) => {
    const showAttemptedData = [];
    for (let i = 0; i < questions.length; i++) {
        const quesObj = questions[i];
        for (let j = 0; j < attempted.length; j++) {
            const attObj = attempted[j];
            if (quesObj.questionId === attObj.questionId) {
                showAttemptedData.push({attemptQuestion: attObj, question: quesObj});
                break;
            } else if (j === attempted.length - 1) {
                showAttemptedData.push({question: quesObj});
            }
        }
    }
    return showAttemptedData;
}

const checkQuestionsValidity = (questionsList) => {

    for (let questionIndex = 0; questionIndex < questionsList.length; questionIndex++) {
        const question = questionsList[questionIndex];
        for (let optionIndex = 0; optionIndex < question.options.length; optionIndex++) {
            const option = question.options[optionIndex];
            if (option.optionText === '' || option.optionText === null) {
                return [-1, (questionIndex + 1)];
            }
        }
    }
    return [1, 0];

    // return questionsList.every((question) => {
    //     const areOptionsValid = question.options.every(
    //         (option) => option.optionText.trim() !== ""
    //     );
    //     return question.questionText.trim() !== "" && areOptionsValid;
    // });
};

export const serialize = (obj) => {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p) && obj[p] && obj[p] !== null && obj[p] !== undefined && (Array.isArray(obj[p]) ? obj[p].length > 0 : true)) {
            if (Array.isArray(obj[p])) {
                for (let val of obj[p]) {
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(val));
                }
            } else {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
    return str.join("&");
}
export const objectToQueryString = (obj) => {
    if (obj === null || obj === undefined || Object.keys(obj)?.length === 0) return ""
    return Object.keys(obj)
        .map(key => {
            if (obj[key] !== null && obj[key] !== undefined) {
                return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
            }
            return null;
        })
        .filter(param => param !== null)
        .join('&');
}
export const updateArrayWithObject = (arr, newObj) => {
    // Check if the object with the same uid already exists in the array
    const existingObj = arr.find(item => item.uid === newObj.uid);

    // If it doesn't exist, push the new object into the array
    if (!existingObj) {
        arr.push(newObj);
    }
    // Return the updated array
    return arr;
}
export const calculatePercentage = (values) => {
    const total = values.reduce((sum, value) => sum + value, 0);
    const percentages = values.map(value => {
        const percentage = (value / total) * 100;
        return Math.round(percentage * 100) / 100; // Rounding off to two decimal points
    });

    const totalPercentage = percentages.reduce((sum, percentage) => sum + percentage, 0);
    if (totalPercentage !== 100) {
        const max = Math.max(...percentages);
        const index = percentages.indexOf(max);
        percentages[index] += 100 - totalPercentage;
    }
    return percentages.map(percentage => Math.round(percentage * 100) / 100);
}


export const toString = (value) => {
    if (value === null || value === undefined || value === "") {
        return ""
    } else {
        const words = value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        return words.join(' ');
    }
}
export const trim = (value, charLength) => {
    return value?.length > charLength ? value.substring(0, charLength) + "..." : value
}


export const filterMentorId = (data, mentorId) => {

    return data?.find(v => v.uid === mentorId)?.firstName || getDataFromLocalStorage("loggedInUserInfo")?.firstName
}
export const TableSkeletonLoading = ({columns, rows}) => {
    return (
        <div className={"loader-wrapper"}>
            {
                [...Array(rows)].map((x, index) => {
                    return (
                        <div key={index}>
                            <div className={"loader-outer"}>
                                {[...Array(columns)].map((x, i) => {
                                    return (
                                        <Skeleton key={i} width={90}/>
                                    );
                                })}

                            </div>
                            <hr className={"hr-line"}/>
                        </div>
                    );
                })
            }
        </div>

    );
}


export function getFilterOperators(operandData) {
    if (operandData === "result" || operandData === "status" || operandData === "surveyName") {
        const type = AttemptSurveyOperation.find(field => field.value === operandData)?.type
        return Operators.filter(operator => operator.type === type)
    } else {
        const type = UserFields.find(field => field.value === operandData)?.type
        return Operators.filter(operator => operator.type === type)
    }

}

export function getFilterOperatorsReports(operandData) {
    if (operandData === "name" || operandData === "status") {
        const type = ReportFiled?.find(field => field?.value === operandData)?.type
        return OperatorsReport?.filter(operator => operator?.type === type)

    }
}

export function filterReflectionFields(fieldName) {
    if (fieldName !== null) {
        const filterList = ReportFiled?.filter(cur => cur.name !== "Select Reflections")
        return filterList.filter(cur => cur.value !== fieldName)
    }
}

export function checkCombination(selectOperand) {
    let foundResult = false;
    let foundStatus = false;

    selectOperand?.forEach((cur) => {
        if (cur.selectOperand === "Attempt Reflection") {
            cur.criteria.forEach((criteriaData) => {
                if (criteriaData.operandField === "result") {
                    foundResult = true;
                }
                if (criteriaData.operandField === "status") {
                    foundStatus = true;
                }
            });
        }
    });

    return foundResult && foundStatus;
}

export function showRecommendedCheckBox(selectOperand) {
    let logo = false
    selectOperand?.map((cur) => {
        if (cur.selectOperand === "Attempt Reflection") {
            cur.criteria.map((criteriaData) => {
                if (criteriaData.operandField === "result") {
                    if (criteriaData.operandValue[0] !== "" && criteriaData.operandValue[0] < 34 && criteriaData.conditionType === "LESS_THEN") {
                        logo = true
                    }
                }
                if (criteriaData.operandField === "status") {
                    logo = criteriaData.operandValue.includes("HIGH_RISK")
                }
            })
        }
    })
    return logo

}


export function validateObject(obj) {
    if (obj?.operandName === null || obj?.operandName === '') {
        return {errorMessage: `Operand Name  can not be null`};
    }
    if (obj?.frequency === null || obj?.operandName === '') {
        return {errorMessage: `frequency  can not be null`};
    }
    for (let operationIndex = 0; operationIndex < obj.operations.length; operationIndex++) {
        const operation = obj.operations[operationIndex];
        if (operation?.selectOperand === "") {
            return {errorMessage: `Operand can not be null or empty of ${operationIndex + 1} `};
        }
        for (let criteriaIndex = 0; criteriaIndex < operation.criteria.length; criteriaIndex++) {
            const criteria = operation.criteria[criteriaIndex];
            if (!criteria.operandField) {
                return {errorMessage: `Operand field cannot be null  of ${criteriaIndex + 1} `};
            }
            if (criteria.operandField === 'firstName' || criteria.operandField === 'lastName') {
                if (!criteria.conditionType) {
                    return {errorMessage: `Condition type cannot be null ${criteriaIndex + 1}`};
                }
                if (criteria.operandValue.length === 0) {
                    return {errorMessage: `Operand value cannot be null or empty ${criteriaIndex + 1}`};
                }
            }
            if (criteria.operandField === 'roles') {
                if (criteria.operandValue.length === 0) {
                    return {errorMessage: `Operand value cannot be null or empty ${criteriaIndex + 1}`};
                }
            }
        }
    }

    for (let actionIndex = 0; actionIndex < obj.action.length; actionIndex++) {
        const actionElement = obj.action[actionIndex];
        if (actionElement.actionType === '') {
            return {errorMessage: `actionType value cannot be null ${actionIndex + 1}`};
        }
        if (actionElement.actionEvents.length === 0) {
            return {errorMessage: `actionEvents  cannot be empty  ${actionIndex + 1}`};
        }

        for (let eventIndex = 0; eventIndex < actionElement.actionEvents.length; eventIndex++) {
            const event = actionElement.actionEvents[eventIndex];
            if (event.actionLabel === "Custom Email" && event.actionValue === '') {
                return {errorMessage: `Custom email cannot be null in action ${actionIndex + 1}, event ${eventIndex + 1}`};
            }
        }
    }
    return true;
}

export function getInputType(operandData) {
    if (operandData === "result" || operandData === "status" || operandData === "surveyName") {
        const type = AttemptSurveyOperation.find(field => field.value === operandData)?.type
        if (type === "String") {
            return "text"
        } else {
            return "Number"
        }
    } else {
        const type = UserFields.find(field => field.value === operandData)?.type
        if (type === "String") {
            return "text"
        } else {
            return "Number"
        }
    }

}

export function showValueField(operandValue, conditionValue, cur, index, reportFilters, setReportFilters, surveyDropDownList, userDropDownList, selectOperand, setSelectOperand, parentId) {
    if (operandValue === "status" && conditionValue === "") {
        return <>
            <Select
                className="react-select-container "
                classNamePrefix="react-select"
                isMulti
                value={cur?.operandValue?.map(value => {
                    let label;
                    switch (value) {
                        case 'HIGH_RISK':
                            label = 'Stagnating';
                            break;
                        case 'LOW_RISK':
                            label = 'Growing';
                            break;
                        case 'GOOD':
                            label = 'Maturing';
                            break;
                        default:
                            label = value;
                    }
                    return {
                        label,
                        value
                    };
                })
                }
                onChange={(selectedOptions) => {
                    const updatedUserCriteria = selectOperand[parentId]?.reportCriteria?.map((filter, i) => {
                        if (i === index) {
                            const updatedValue = selectedOptions?.map(option => option?.value); // Update value based on selected options
                            return {
                                ...filter,
                                operandValue: updatedValue,
                            };
                        }
                        return filter;
                    });
                    setSelectOperand(prevUserCriteria => {
                        const updatedSelectOperand = [...prevUserCriteria];
                        updatedSelectOperand[parentId] = {
                            ...updatedSelectOperand[parentId],
                            reportCriteria: updatedUserCriteria
                        };
                        return updatedSelectOperand;
                    });
                }}
                options={createOptionListForSelectTag(ReflectionStatus, "name", "value")}
                isClearable={true}
                placeholder={"Status ..."}/>
        </>
    }
    if (operandValue === "roles") {
        return (

            <>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name={"operandValue"}
                    value={cur?.operandValue?.map(value => ({
                        label: value,
                        value
                    }))}
                    isMulti
                    options={createOptionListForSelectTag(RolesData?.filter(role => role.name !== Roles.SUB_ORG_ADMIN), "name", "value")}
                    onChange={(selectedOptions) => {
                        const updatedUserCriteria = selectOperand[parentId]?.reportCriteria.map((filter, i) => {
                            if (i === index) {
                                const updatedValue = selectedOptions?.map(option => option.value); // Update value based on selected options
                                return {
                                    ...filter,
                                    operandValue: updatedValue,
                                };
                            }
                            return filter;
                        });
                        setSelectOperand(prevUserCriteria => {
                            const updatedSelectOperand = [...prevUserCriteria];
                            updatedSelectOperand[parentId] = {
                                ...updatedSelectOperand[parentId],
                                reportCriteria: updatedUserCriteria
                            };
                            return updatedSelectOperand;
                        });
                    }}
                    placeholder={"Select Role ..."}/>
            </>
        )

    }
    if (operandValue === "UserSelection") {
        return (
            <>
                <Select
                    className="react-select-container "
                    classNamePrefix="react-select"
                    isMulti
                    options={userDropDownList}
                    value={userDropDownList?.filter(option => cur?.operandValue?.includes(option?.value))} // Pre-select options based on cur.operandValue
                    isClearable={true}
                    onChange={(selectedOptions) => {
                        const updatedUserCriteria = selectOperand[parentId]?.reportCriteria?.map((filter, i) => {
                            if (i === index) {
                                const updatedValue = selectedOptions?.map(option => option?.value); // Update value based on selected options
                                return {
                                    ...filter,
                                    operandValue: updatedValue,
                                };
                            }
                            return filter;
                        });
                        setSelectOperand(prevUserCriteria => {
                            const updatedSelectOperand = [...prevUserCriteria];
                            updatedSelectOperand[parentId] = {
                                ...updatedSelectOperand[parentId],
                                reportCriteria: updatedUserCriteria
                            };
                            return updatedSelectOperand;
                        });
                    }}
                    placeholder={"Members..."}/>
            </>
        )
    }

    if (operandValue === "RefSelection") {
        return (
            <>
                <Select
                    className="react-select-container "
                    classNamePrefix="react-select"
                    options={surveyDropDownList}
                    value={surveyDropDownList?.filter(option => cur?.operandValue?.includes(option?.value))} // Pre-select options based on cur.operandValue
                    onChange={(selectedOptions) => {
                        const updatedUserCriteria = selectOperand[parentId]?.reportCriteria.map((filter, i) => {
                            if (i === index) {
                                const updatedValue = selectedOptions?.map(option => option.value); // Update value based on selected options
                                return {
                                    ...filter,
                                    operandValue: updatedValue,
                                };
                            }
                            return filter;
                        });
                        setSelectOperand(prevUserCriteria => {
                            const updatedSelectOperand = [...prevUserCriteria];
                            updatedSelectOperand[parentId] = {
                                ...updatedSelectOperand[parentId],
                                reportCriteria: updatedUserCriteria
                            };
                            return updatedSelectOperand;
                        });
                    }}
                    isMulti
                    isClearable={true}
                    placeholder={"Reflections ..."}/>
            </>
        )
    } else {

        return (
            <>
                <input className={"form-control ms-2"}
                       placeholder={"Enter value"} name={"operandValue"} value={cur.operandValue}
                       onChange={(e) => {
                           const updatedUserCriteria = selectOperand[parentId]?.reportCriteria.map((filter, i) => {
                               if (i === index) {
                                   return {
                                       ...filter,
                                       [e.target.name]: e.target.name === "operandValue" ? [e.target.value] : e.target.value,
                                   };
                               }
                               return filter;
                           });
                           setSelectOperand(prevUserCriteria => {
                               const updatedSelectOperand = [...prevUserCriteria];
                               updatedSelectOperand[parentId] = {
                                   ...updatedSelectOperand[parentId],
                                   reportCriteria: updatedUserCriteria
                               };
                               return updatedSelectOperand;
                           });
                       }}/>
            </>
        )

    }

}

export function getFilterInputs(operandData) {
    const type = UserFields.find(field => field.value === operandData)?.type
    return Inputs.filter(input => input.type === type)
}

export function computeFilterWhatExactlyDo(operandData, conditionType, value) {
    const type = UserFields.find(field => field.value === operandData)?.type
    if (type === "String" && operandData !== "" && conditionType !== "" && value !== "") {
        console.log(`==> This filter ${operandData} ${conditionType} ${value}`);

    } else {

    }
    return Inputs.filter(input => input.type === type)
}

export const displayInfoData = (operandData, conditionType, value, operatorValue) => {
    const typeData = UserFields.find(field => field.value === operandData)?.type
    if (typeData === "String" && operandData !== "" && conditionType !== "" && value !== "") {
        return (
            <>
                <div className={"info-icon"} data-tooltip-id="DeleteReflectionBTN"
                     data-tooltip-content={conditionType === "Equals" ? ` This action considering that ${operandData} ${conditionType} ( exact match) ${value}` : `This action considering that ${operandData} ${conditionType} (related to) ${value}`}>

                    <FaInfoCircle/>
                </div>

                <ReactTooltip className={"ToolTip"} place={"bottom"} id="DeleteReflectionBTN"/>
            </>


        )

    }

    if (typeData === "Array" && operandData !== "" && operandData === "roles") {

        if (operatorValue?.length > 0 && conditionType === "" && value === "") {
            return (
                <>
                    <div className={"info-icon"} data-tooltip-id="DeleteReflectionBTN"
                         data-tooltip-content={` This action considering that if when user register as a ${operatorValue?.map(cur => cur)} `

                         }>

                        <FaInfoCircle/>
                    </div>

                    <ReactTooltip className={"ToolTip"} place={"bottom"} id="DeleteReflectionBTN"/>
                </>


            )
        }
        if (operatorValue?.length > 0 && conditionType !== "" && value !== "") {
            return (
                <>
                    <div className={"info-icon"} data-tooltip-id="DeleteReflectionBTN"
                         data-tooltip-content={` This action considering that if  ${operatorValue?.map(cur => cur)} has count ${conditionType} ${value}`

                         }>
                        <FaInfoCircle/>
                    </div>

                    <ReactTooltip className={"ToolTip"} place={"bottom"} id="DeleteReflectionBTN"/>
                </>


            )
        }


    }

}
export const getDaysLeft = (currentDate, expiryDate) => {
    const date1 = new Date(currentDate);
    const date2 = new Date(expiryDate);
    const timeDifference = date2 - date1;

    return (Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));
}
export const tableCustomStyling = {
    headCells: {
        style: {
            fontSize: "13px",
            fontFamily: "General Sans Medium",
            fontWeight: "800",
            background: "#F9FAFB",
        },
    },
    rows: {
        style: {
            padding: "10px 0px",
        },
    },
};

export function showYContent(reportVariables) {
    if (reportVariables?.labelY === "name") {

        return (
            <>
                <p>{reportVariables?.titleY}</p>
            </>
        )
    }

    if (reportVariables?.labelY === "status") {
        return (
            <>
                <p>{reportVariables?.titleY}</p>
            </>
        )
    }

}

export function showXContent(reportVariables) {
    if (reportVariables?.labelX === "name") {

        return (
            <>
                <p>{reportVariables?.titleX}</p>
            </>
        )
    }

    if (reportVariables?.labelX === "status") {
        return (
            <>
                <p>{reportVariables?.titleX}</p>
            </>
        )
    }

}

export function getInitials(fullName) {
    if (fullName === null || fullName === undefined || fullName === "") {
        return ""
    }
    const names = fullName.split(" ");
    const firstName = names[0];
    const lastName = names.length > 1 ? names[1] : "";

    if (!lastName || lastName.trim() === "") {
        return firstName.substring(0, 2).toUpperCase();
    } else {
        return (firstName[0] + lastName[0]).toUpperCase();
    }
}

export const formatAndComputeAutoDateRange = (startDate, endDate, formatType) => {
    const currentDate = new Date();
    if (!startDate && endDate) {
        startDate = new Date(0);
    } else if (startDate && !endDate) {
        endDate = currentDate;
    } else if (!startDate && !endDate) {
        return null;
    }
    const formattedStartDate = format(startDate, formatType)
    const formattedEndDate = format(endDate, formatType)
    return {startDate: formattedStartDate, endDate: formattedEndDate};
};
export const computeBackDate = (daysToMinus) => {
    const date = new Date()
    const resultData = new Date(date.setDate(date.getDate() - daysToMinus))
    return resultData;
};
export const getValueOrDefault = (value, defaultValue) => {
    if (value === null || value === undefined || value === "") {
        return defaultValue
    }
    return value
}
export const getBackgroundColorOnResult = (result) => {
    if (result <= 33) {
        return 'red';
    } else if (result <= 66 && result >= 34) {
        return '#ff7100'
    } else {
        return 'green'
    }

}

export const getLabelColorOnDisabledReflection = (graphData = [], key1, reflectionList = [], key2) => {
    if (graphData?.length === 0 || reflectionList?.length === 0) {
        return "#666666"
    }
    const results = [];

    // Loop through the first array
    graphData?.forEach((item1) => {
        // Check if the surveyName exists in the second array's name values
        const found = reflectionList.some((item2) => item2[key2] === item1[key1]);

        // Push the color (true or false) into the results array
        results.push(found ? "black" : "grey");
    });

    // Return the results array
    return results;
}


export const getGraphWidth = (totalBars = null, screenWidth, graphName) => {
    if (totalBars === null) {
        return "100%"
    }

    if (graphName === "USER_SUMMARY_LINE_GRAPH") {
        if (screenWidth > 1237) {
            const spaceForGraph = (screenWidth - 360) / 2
            return (totalBars * 50) <= spaceForGraph ? "100%" : (totalBars * 50) + "px"
        } else {
            const spaceForGraph = (screenWidth - 360)
            return (totalBars * 50) <= spaceForGraph ? "100%" : (totalBars * 50) + "px"
        }


    } else {
        const spaceForGraph = screenWidth - 360
        if (screenWidth > 1000) {
            return (totalBars * 90) <= spaceForGraph ? "100%" : (totalBars * 90) + "px"
        } else if (screenWidth <= 1000 && screenWidth > 767) {
            return (totalBars * 70) <= spaceForGraph ? "100%" : (totalBars * 70) + "px"
        } else if (screenWidth <= 767) {
            return (totalBars * 50) <= spaceForGraph ? "100%" : (totalBars * 50) + "px"
        }
    }
    return "100%"
}
export const createOptionListForSelectTag = (optionsList, label, value, isSelf = false) => {

    let newList = optionsList !== undefined && optionsList !== null ? [...optionsList] : [];

    if (isSelf) {
        newList.unshift({[value]: jwtDecode(getDataFromLocalStorage("token"))?.orgId, [label]: "Self"});
    }

    return newList.map(currentOption => ({
        value: currentOption[value], // Assuming `value` is a variable containing the key for the value property
        label: String(currentOption[label]) // `toString` changed to `String` for conversion
    }));
}
export const createOptionListForSelectTagWithMultipleLabels = (optionsList, labels = [], value) => {
    let newList = optionsList !== undefined && optionsList !== null ? [...optionsList] : [];

    return newList.map(currentOption => ({
        value: currentOption[value], // Assuming `value` is a variable containing the key for the value property
        label: labels?.map(label => currentOption[label]).join(' ')
    }));
}
export const createOptionListForSelectTagWithMultipleLabelsWithAdditionalInfo = (optionsList, labels = [], value, addInfoKey) => {
    let newList = optionsList !== undefined && optionsList !== null ? [...optionsList] : [];

    return newList.map(currentOption => ({
        value: currentOption[value], // Assuming `value` is a variable containing the key for the value property
        label: (labels?.map(label => currentOption[label]).join(' ')) + " " + (" (" + currentOption?.[addInfoKey] + ")")
    }));
}
export const createOptionListForSelectTagWithRole = (optionsList, label, value, excludeAny = false, keyToCompare, valuesToCompare = []) => {
    let list = optionsList
    if (excludeAny && keyToCompare && valuesToCompare?.length > 0) {
        list = list?.filter((option) => {
            return !valuesToCompare.includes(option[keyToCompare])
        })
    }
    return optionsList !== undefined & optionsList !== null ? list?.map(currentOption => ({
        value: currentOption[value],
        label: toString(currentOption[label]) + " " + (" (" + currentOption?.roleObjects?.[0].name.toLowerCase() + ")")
    })) : [];
}

export const createSelectedValuesForSelectTag = (optionsList, currentList, label, value) => {
    return optionsList !== undefined & optionsList !== null ? currentList.map(currentOption => ({
        value: optionsList.find(c => c[value] === currentOption)[value],
        label: toString(optionsList.find(c => c[value] === currentOption)[label])
    })) : [];
}


export const BulkDeleteIcon = ({selectCheckBoxItems, setShowBulkDeleteConfirmationModal}) => {
    return (

        Array.isArray(selectCheckBoxItems) && selectCheckBoxItems.length > 0 && (
            <div className="reflection_filters">

                <button className={"bulk-btn"} onClick={() => setShowBulkDeleteConfirmationModal(true)}>
                    <FiTrash2
                        className={"fa fa-trash bulk_trash_icon ms-2"}
                        title={"Bulk Delete"}
                        alt=""
                    /> <span className={"me-2"}>Delete</span>
                </button>


            </div>
        )
    )
}


export const planAmountFormatting = (amt, interval) => {
    return interval === 'month' ? "$" + amt?.toFixed(2) + "/" + "mon" : "$" + amt?.toFixed(2) + "/" + "yr";
}

export const applyValidDateRange = (dateRange) => {
    return (dateRange?.startDate === null || dateRange?.startDate === "" || dateRange?.startDate === undefined) && (dateRange?.endDate === null || dateRange?.endDate === "" || dateRange?.endDate === undefined) ? null : dateRange;
};

// export function resourceToFile(response, fileName, fileExt = '.csv',returnBlob=false) {
//     let instance = new Date();
//
//     const blob = new Blob([response]);
//     if(returnBlob===true){
//         return blob;
//     }
//
//     const url = window.URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', fileName + "_" + (instance.getMonth() + 1) + "-" + instance.getDate() + "-" + instance.getFullYear()  + fileExt);
//     document.body.appendChild(link);
//     link.click();
// }
export function resourceToFile(response, fileName, fileExt = '.csv', returnBlob = false) {

    let instance = new Date();
    const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    if (returnBlob === true) {
        return blob;
    }
    const reader = new FileReader();
    reader.onload = function () {
        const url = window.URL.createObjectURL(new Blob([reader.result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName + "_" + (instance.getMonth() + 1) + "-" + instance.getDate() + "-" + instance.getFullYear() + fileExt);
        document.body.appendChild(link);
        link.click();
    };
    reader.readAsArrayBuffer(blob);
}

export const validatePlanVisibility = (currentPlan, desirePlan) => {
    if (currentPlan === null || currentPlan === "") {
        return true;
    }
    let currentLevelConst = Object.keys(PulsePlans).find(c => PulsePlans[c].value === currentPlan);
    let desiredLevelConst = Object.keys(PulsePlans).find(c => PulsePlans[c].value === desirePlan);
    return PulsePlans[desiredLevelConst].ordinal > PulsePlans[currentLevelConst].ordinal;


}

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
export const getCurrentAndPreviousMonths = () => {
    const last6Months = [];
    const months = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];
    const data = new Date()

    for (let i = 0; i < 6; i++) {
        const currentMonthIndex = (data.getMonth() - i + 12) % 12;
        last6Months.push(months[currentMonthIndex]);
    }
    return last6Months.reverse();
}


export const getCombinedReportResult = (reportResult = []) => {
    // Check if the reportResult array is empty
    if (reportResult.length === 0) {
        return "N/A";
    }

    // Calculate the total avgCount, treating null or undefined values as 0
    const total = reportResult.reduce((sum, value) => sum + (value?.avgCount ?? 0), 0);

    // Calculate the average
    let avg = total === 0 ? 0 : total / reportResult.length;
    avg = avg.toFixed(2);

    // Ensure avg is treated as a number for comparison
    avg = parseFloat(avg);

    // Return the appropriate string based on avg value
    if (avg < MarksNumbers.PASS_PERCENTAGE) {
        return `${Marks.HIGH_RISK} (${avg})`;
    } else if (avg <= MarksNumbers.GOOD_PERCENTAGE) {
        return `${Marks.LOW_RISK} (${avg})`;
    } else {
        return `${Marks.GOOD} (${avg})`;
    }
};
export const getQueryParam = (queryString, key) => {
    const params = new URLSearchParams(queryString);
    return params.get(key);
};
export const showToast = (message = "", type = "") => {
    if (message === null || message === undefined || message === "" || type === null || type === undefined || type === "") {
        return ""
    }
    if (type.toUpperCase() === "SUCCESS") {
        toast.isActive("success") ? toast.update("success", {
            type: toast.TYPE.SUCCESS,
            render: message
        }) : toast.success(message, {toastId: "success"})
    }
    if (type.toUpperCase() === "ERROR") {
        toast.isActive("error") ? toast.update("error", {
            type: toast.TYPE.ERROR,
            render: message
        }) : toast.error(message, {toastId: "error"})
    }
    if (type.toUpperCase() === "INFO") {
        toast.isActive("info") ? toast.update("info", {
            type: toast.TYPE.INFO,
            render: message
        }) : toast.info(message, {toastId: "info"})
    }
};

export const getRolesWithAuthorityLevel = (operator, number, excludedRoles = []) => {
    return Object.entries(AuthorityLevel)
        .filter(([key, value]) => {
            switch (operator) {
                case '===':
                    return value === number;
                case '<=':
                    return value <= number;
                case '>=':
                    return value >= number;
                case '>':
                    return value > number;
                case '<':
                    return value < number;
                case '!==':
                    return value !== number;
                default:
                    return false;
            }
        })
        .map(([key]) => key)
        .filter(key => !excludedRoles.includes(key));
};

export const getAuthorityLevelFor = (role) => {
    if (role === null || role === undefined || role === "") {
        return 0
    }
    return AuthorityLevel[role]
}

export const generateFakeEmail = (firstName = '', lastName = '') => {
    const cleanedFirstName = removeSpecialCharacters(firstName);
    const cleanedLastName = removeSpecialCharacters(lastName);

    let generatedEmail;
    if (cleanedFirstName && cleanedLastName) {
        generatedEmail = `${cleanedFirstName}.${cleanedLastName}`;
    } else {
        generatedEmail = cleanedFirstName || cleanedLastName;
    }

    return `${generatedEmail}${Date.now()}@fake.com`;
};


export const removeSpecialCharacters = (str) => {
    return str.replace(/[^a-zA-Z]/g, '');
};


export const eliminateDuplicateObjects = (removeDuplicatesFrom, secondArray, keyToCompare) => {
    const firstArrayKeys = new Set(secondArray.map(item => item[keyToCompare]));
    const resultArray = removeDuplicatesFrom.filter(item => !firstArrayKeys.has(item[keyToCompare]));
    return resultArray;
};
export const eliminateDuplicatesFromArray = (removeDuplicatesFrom) => {
    if (Array.isArray(removeDuplicatesFrom)) {
        return Array.from(new Set(removeDuplicatesFrom))
    }
    return removeDuplicatesFrom
};

export const validateCreateTeamObject = (createTeamData) => {
    if (createTeamData?.teamName === null || createTeamData?.teamName === undefined || createTeamData?.teamName === "") {
        return formatMessage(IsRequired, ["Team Name"])
    }
    if (createTeamData?.managedBy?.length === 0) {
        return formatMessage(SelectAtLeastOne, ["leader"])
    }
    if (createTeamData?.membersIds?.length === 0) {
        return formatMessage(SelectAtLeastOne, ["member"])
    }
};

export const validateUpdateTeamObject = (updateTeamData) => {
    if (updateTeamData?.teamName === null || updateTeamData?.teamName === undefined || updateTeamData?.teamName === "") {
        return formatMessage(IsRequired, ["Team Name"])
    }
    if (updateTeamData?.managedBy?.length === 0) {
        return formatMessage(UpdateTeamErrorSelectAtLeastOne, ["leader"])
    }
    if (updateTeamData?.membersIds?.length === 0) {
        return formatMessage(UpdateTeamErrorSelectAtLeastOne, ["member"])
    }
};

export const getRolesObjectExcluding = (roles, excludedRoles) => {
    if (Array.isArray(roles)) {
        return roles
            .filter(key => !excludedRoles.includes(Roles[key]))
            .map(key => ({name: toString(Roles[key]), value: Roles[key]}));
    }
    return Object.keys(roles)
        .filter(key => !excludedRoles.includes(Roles[key]))
        .map(key => ({name: toString(Roles[key]), value: Roles[key]}));
};

export const getBlobForMemberAttemptSummaryGraph = (data) => {
    if (data === null || data === undefined) {
        return null;
    }
    const headers = ["Mentee Name", "Total Count", "Low Risk Count", "High Risk Count", "Good Risk Count", "No Result Count"];
    const rows = data?.map(item => [
        item?.menteeName,
        item?.totalCount,
        item?.lowRiskCount,
        item?.highRiskCount,
        item?.goodRiskCount,
        item?.noResultCount,
    ]);
    const csvContent = [
        headers.join(','), // Header row
        ...rows.map(row => row.join(','))
    ].join('\n');

    return new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});

}

export const getBlobForReflectionSummaryGraph = (data) => {
    if (data === null || data === undefined) {
        return null;
    }
    const headers = ["Survey Name", "Created At", "Aggregate Reflection Result", "Count"];
    const rows = data?.map(item => [
        item?.surveyName,
        formatDate(item?.createdAt),
        item?.aggregateReflectionResult,
        item?.count,
    ]);
    const csvContent = [
        headers.join(','), // Header row
        ...rows.map(row => row.join(','))
    ].join('\n');

    return new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});

}

export const isValidCreateStrategyData = (data) => {
    if (isNullOrEmpty(data.name)) {
        showToast(formatMessage(IsRequired, ["Strategy name"]), "ERROR")
        return false;
    }
    if (isNullOrEmpty(data.description)) {
        showToast(formatMessage(ProvideStrategyDescription, []), "ERROR")
        return false;
    }
    if ((data?.org === null || data?.org === undefined) && (data?.orgId === null || data?.orgId === undefined)) {
        showToast(formatMessage(IsRequired, ["Organisation"]), "ERROR")
        return false;
    }
    return true;
}